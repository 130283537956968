import { Injectable } from "@angular/core";
import { MIMIR_API } from "./urls_b2b";
import { BaseService } from "./base.service";
import { ShareVars } from "./share.vars";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class MimirService {
    platform = ""
	platform_id = ""
	agency = ""
	agency_id = ""
	username = ""
    mimir_token = ""
    date_exp = ""
    token = ""

	constructor(private cookieService: CookieService, private http: HttpClient) {}

    async log(event: Partial<MimirEvent>, func?: Function) {
        const agency = ShareVars.currentAgency
        try {
            this.platform_id = agency['platform'].id
            this.platform = agency['platform'].name
            this.agency_id = agency['id']
            this.agency = agency['name']
            this.username = ShareVars.currentUser.username
            this.mimir_token = ShareVars.currentUser.mimir_token
            this.date_exp = ShareVars.currentUser.mimir_expiration_date
            this.token = this.cookieService.get('token')
        } catch {
            func()
            return
        }

        if (!this.platform_id ||
            !this.platform ||
            !this.agency_id ||
            !this.agency ||
            !this.username ||
            !this.mimir_token ||
            !this.date_exp ||
            !this.token
        ) {
            func()
            return
        }

        const data = {
            ...event,
            params_str: [event.param1, event.param2, event.param3, event.param4].filter(param => param !== null).join('|'),
            platform_id: this.platform_id,
            platform: this.platform,
            agency_id: this.agency_id,
            agency: this.agency,
            username: this.username,
            mimir_token: this.mimir_token,
            date_exp: this.date_exp,
            token: this.token
        }
        try {
            await this.http.post(MIMIR_API, data).toPromise().finally(() => {
                func && func();
                return;
            })
        } catch {
            func && func();
            return;
        }
    }
}
