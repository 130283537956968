export class ShareVars {
	static environmentClientName: string = null;
	static isBusy: boolean = true;
	static disableNavButtons: boolean = false;

	static currentAgency = [];
	static authorizations = [];
	static currentUser = null;

	static readonly SEARCH_FLIGHT_DEPARTURE_INPUT_ID = "flight_search_departure_flight_input_";
	static readonly SEARCH_HOTEL_DEPARTURE_INPUT_ID = "hotel_search_destination_input";
	static readonly SEARCH_HOTEL_PACKAGE_INPUT_ID = "package_search_destination_input";

	static waitingB2BUser: boolean = false;
	static b2bLocalDepositBalance: number = 0;
	static b2bForeignDepositBalance: number = 0;
	static b2bOverdraw: number = 0;

	static lastActiveTab: number = 0;

	static showError: boolean = false;
	static errorMessage: string = null;
	static errorStatus: number = null;
	static errorTitle: string = null;
	static errorPayment: string = null;
	static showHome: boolean = false;

	static showChildLoader: boolean = false;
	static childLoaderMessage: string = null;

	static showFlightResultsError: boolean = false;
	static showFlightResultsErrorMessage: string = null;

	static showPackageResultsError: boolean = false;
	static showPackageResultsErrorMessage: string = null;

	static showHotelResultsError: boolean = false;
	static showHotelResultsErrorMessage: string = null;

	static showHotelItemResultsError: boolean = false;
	static showHotelItemResultsErrorMessage: string = null;

	static showCharterResultsError: boolean = false;
	static showCharterResultsErrorMessage: string = null;

	static mainLoaderMessage: string = null;

	static showPackageTab: boolean = false;
	static showFlightTab: boolean = true;
	static showHotelTab: boolean = false;
	static showVisaTab: boolean = false;
	static showInsuranceTab: boolean = false;
	static showCharterTab: boolean = false;
	static usingMenu: boolean = false;

	static disableRecommendation = [];
	static disableCombination = [];

	static selectedFlightRec: number = null;
	static selectedFlightComb: number = null;
	static selectedRetFlightKey = null;
	static selectedDepFlightKey = null;
	static selectedBookingKey = null;

	static minDateFlightSearch = [];

	static signUpUser = {};
	static signUpModalOn: boolean = false;
	static signUpFailed: boolean = false;
	static signUpSucceeded: boolean = false;
	static signup_from;

	static showMenuNav = [false, false, false, false, false, false];

	static excludeErrors = [
		"INCORECT_USERNAME_OR_PASSWORD",
		"INCORECT_USERNAME",
		"AUTHENTICATION_CODE_HAS_BEEN_SENT_BY_EMAIL",
	];

	static countryCodesDictionnary = {};
	static countryNameDictionnary = {};
	static countryCodes = [];
	static countryNames = [];
	static callingCodes = [];
	static hotelOffers = [];
	static charters = [];
	static departements = [];

	constructor() {}

	static initSignUpData() {
		if (this.signup_from) {
			this.signup_from.reset();
		}

		this.signUpFailed = false;
		this.signUpSucceeded = false;
		this.signUpUser = {};
	}

	static enableBillingInfoTab: boolean = false;
	static currentSelectedFlightRecommendation = null;
	static inB2BLogin: boolean = false;

	static SELECTED_INSURANCE: string = "Amana";

	static showTreeSearch: boolean = false;
	static treeSearchDeparture = {
		description: "Ex. New York",
		descriptionEn: "Ex. New York",
		id: null,
	};
	static treeProviders = [];
	static usingTreeSearch: boolean = false;

	static showHotelSearch: boolean = true;
	static showFlightSearch: boolean = true;
	static debounceTime = 500;
	static setCountriesInfo(data) {
		for (let i = 0; i < data.length; i++) {
			let countryFullCode = data[i].alpha3Code + "-" + data[i].alpha2Code;
			ShareVars.countryCodes.push(countryFullCode);
			ShareVars.countryNames.push(data[i].name);
			ShareVars.callingCodes.push("+" + data[i].callingCodes[0] + " " + data[i].name);

			ShareVars.countryNameDictionnary[countryFullCode] = data[i].name;
			ShareVars.countryCodesDictionnary[data[i].alpha2Code] = countryFullCode;
		}
	}

	static checkAuthorization(authorization) {
		return this.authorizations.includes(authorization);
	}
}
