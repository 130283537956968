export class PackageTravellerModel {
	civility: string;
	firstName: string;
	lastName: string;
	gender: string;
	travellerIndex: number;
	travellerType: string;
	travellerTypeName: string;
	travellerPassportNumber: number;
	travellerPassportExpDate: string;
	room_code: number;

	constructor() {
		this.civility = "Mr";
		this.firstName = null;
		this.lastName = null;
		this.gender = "M";
		this.travellerIndex = 0;
		this.travellerType = "";
		this.travellerTypeName = "";
		this.travellerPassportNumber = null;
		this.travellerPassportExpDate = null;
		this.room_code = 0;

	}
}
