import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { COMMUNES } from "@app/shared/services/misc/mock-communes";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { NavigationService } from "@app/shared/services/navigation.service";
import { LocalizationService } from "@app/shared/services/localization.service";
import { FlightService } from "@app/shared/services/flight.service";
import { BaseService } from "@app/shared/services/base.service";
import { DateUtils } from "@app/shared/services/utils";
import { DateService } from "../../services/date.service";
import { ShareVars } from "../../services/share.vars";
import { NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { FlightSearchFiledsModel } from "../../../main/components/flight-seach-fields-model/flight.search.fileds.model";
import {
	FLIGHT_PASSENGERS_NUMBER_ERROR,
	FLIGHT_BABIES_NUMBER_ERROR,
} from "../../services/display.messages";
import { FLIGHT_BOOKING_ROUTE, AVAILABILITY_CHILD_ROUTE } from "../../services/routes.pages";
import { cloneDeep } from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { LoginService } from "@app/shared/services/login.service";
import { MimirService } from "@app/shared/services/mimir.service";

const FLIGHT_SEARCH_PARAMS = "flightSearchParams";

declare var fbq;
declare var lintrk;

@Component({
	selector: "app-flight-search-shared",
	templateUrl: "./flight-search-shared.component.html",
	styleUrls: ["./flight-search-shared.component.scss"],
	providers: [NgbDatepickerConfig],
})
export class FlightSearchSharedComponent implements OnInit {
	//used in pickDateMobile
	modelDeparture = null;
	modelReturn = null;

	communes = COMMUNES;
	wilayas;
	selectedWilayaName: string = "Adrar";
	selectedWilaya = this.communes["Adrar"];
	selectedCommuneName = this.communes["Adrar"][0]["name"];
	selectedCommune = this.communes["Adrar"][0];

	location;

	adultNumber: number = 1;
	infantNumber: number = 0;
	childNumber: number = 0;
	infantSeatNumber: number = 0;
	youthNumber: number = 0;
	seniorNumber: number = 0;

	mcDepartures = [[{
		departureName: "",
		departureDisplay: "",
		departureItem: null
	}]];
	mcArrivals = [[{
		arrivalName: "",
		arrivalDisplay: "",
		arrivalItem: null
	}]];

	mcDates = [
		DateService.getTodayDate()
	];

	searchParamsDisplayName = [
		{
			departureName: "",
			departureDisplay: "",
			arrivalName: "",
			arrivalDisplay: "",
		},
		{
			departureName: "",
			departureDisplay: "",
			arrivalName: "",
			arrivalDisplay: "",
		},
		{
			departureName: "",
			departureDisplay: "",
			arrivalName: "",
			arrivalDisplay: "",
		},
	];

	rtType: string;
	cabinClass: string = "Y";
	cabinClassName = "ECONOMY";

	// TODO duplicate
	classCorrespondances = ["M", "Y", "W", "C", "F", "X", "I"];
	classTitles = [
		"ECONOMY_STANDARD",
		"ECONOMY",
		"ECONOMY_PREMIUM",
		"BUSINESS",
		"FIRST",
		"MULTIPLE",
		"INDIFFERENT",
	];

	flightForm: FormGroup;
	flightDepartureSpinner = false;
	flightArrivalSpinner = false;

	flightDepartureName = null;
	flightArrivalName = null;

	shareVarsRef = ShareVars;
	arrayFieldsModel = new Array();

	plateformAdmin: boolean;

	@Output() agencyChoiceModal = new EventEmitter();

	agencyChoice = {
		showModal: false,
	};

	availabilityRoute: string = FLIGHT_BOOKING_ROUTE + AVAILABILITY_CHILD_ROUTE;
	availabilityRouteParams = {};

	@Input() searchSource: string;
	usingAvailabilityRouteParams: boolean = false;

	// TODO duplicate
	classDropDownID = [
		"flight_search_class_dropdown_indifferent",
		"flight_search_class_dropdown_economy_s",
		"flight_search_class_dropdown_economy",
		"flight_search_class_dropdown_economy_p",
		"flight_search_class_dropdown_business",
		"flight_search_class_dropdown_first",
		"flight_search_class_dropdown_multiple",
	];
	classDropDownText = [
		"ECONOMY_STANDARD",
		"ECONOMY",
		"ECONOMY_PREMIUM",
		"BUSINESS",
		"FIRST",
		"MULTIPLE",
		"INDIFFERENT",
	];

	bound_classDropDownID: Array<string>;
	bound_classDropDownText: Array<string>;

	classDropDownID_CORPORATE: string[] = [
		"flight_search_class_dropdown_economy",
		"flight_search_class_dropdown_business",
		"flight_search_class_dropdown_multiple",
	];
	classDropDownText_CORPORATE = ["ECONOMY", "BUSINESS", "MULTIPLE"];

	displayMultipleCabinClass: boolean = false;

	currentAgency;

	constructor(
		ngbDatepickerConfig: NgbDatepickerConfig,
		private baseService: BaseService,
		private navigationService: NavigationService,
		public flightService: FlightService,
		private localizationService: LocalizationService,
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private loginService: LoginService,
		private mimirService: MimirService
	) {
		this.modelDeparture = DateService.getTodayDate();
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};

		this.initSearchFields();
		this.bound_classDropDownID = [...this.classDropDownID];
		this.bound_classDropDownText = [...this.classDropDownText];

		/*this.baseService.getGPSPosition().then((position: any) => {
		this.location = position.coords;
		this.findNearest(this.location);
    });*/
		this.plateformAdmin = false;
		this.baseService.getB2BUser().subscribe((data) => {
			if (data) {
				if (
					data["authorizations"].includes("super_admin_authorizations") ||
					data["authorizations"].includes("platform_admin_authorizations")
				) {
					this.plateformAdmin = true;
				}
			}
		});
	}

	ngOnInit() {
		this.mcDepartures = localStorage.getItem("flightMcDepartures") ? JSON.parse(localStorage.getItem("flightMcDepartures")) : this.mcDepartures
		this.mcArrivals = localStorage.getItem("flightMcArrivals") ? JSON.parse(localStorage.getItem("flightMcArrivals")) : this.mcArrivals
		let lsDates: any = localStorage.getItem("flightMcDates")
		if (lsDates) {
			lsDates = JSON.parse(lsDates)
			let dates_array = lsDates as string[];
			this.mcDates = dates_array.map(d => {
				const s = d.split('-')
				return {
					year: parseInt(s[0]),
					month: parseInt(s[1]),
					day: parseInt(s[2])
				}
			})
		}
		switch (this.searchSource) {
			case "flight":
				this.initFlightSearch();
				break;

			case "flight_availability":
				this.activatedRoute.queryParams.subscribe((urlParams) => {
					if (Object.keys(urlParams).length == 0) {
						this.usingAvailabilityRouteParams = false;
					} else {
						this.usingAvailabilityRouteParams = true;
					}

					this.initFlightSearch(urlParams);
				});

				break;
		}

		this.currentAgency = this.shareVarsRef.currentAgency;
		if (this.currentAgency && this.currentAgency.corporate) {
			this.classDropDownText = this.classDropDownText_CORPORATE;
			this.classDropDownID = this.classDropDownID_CORPORATE;

			this.bound_classDropDownID = [...this.classDropDownID];
			this.bound_classDropDownText = [...this.classDropDownText];

			this.cabinClassName = "ECONOMY";
		}
	}

	focusOnElement() {
		this.baseService.focusOnElement(ShareVars.SEARCH_FLIGHT_DEPARTURE_INPUT_ID + 0);
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	viewAdult(e) {
		console.log(this.adultNumber);
	}

	findNearest(p) {
		if (!p) {
			return;
		}
		let min = 100000;
		let commune = "";
		let wilaya = "";
		// tslint:disable-next-line:forin
		for (const w in this.communes) {
			for (const c in this.communes[w]) {
				if (this.communes[w][c]["gps"][0] !== "") {
					const clong = Number(this.communes[w][c]["gps"][0]);
					const clat = Number(this.communes[w][c]["gps"][1]);
					const d = Math.sqrt(
						Math.pow(clat - p["latitude"], 2) + Math.pow(clong - p["longitude"], 2)
					);
					if (min > d) {
						min = d;
						commune = c;
						wilaya = w;
					}
				}
			}
		}
		this.setWilaya(wilaya);
		this.setCommune(this.communes[wilaya][commune]);
		// console.log(wilaya, commune);
	}

	setWilaya(w) {
		this.selectedWilayaName = w["name"];
		this.selectedWilaya = this.communes[w["name"]];
		this.selectedCommuneName = this.communes[w["name"]][0]["name"];
		this.selectedCommune = this.communes[w["name"]][0];
		// console.log(this.selectedWilaya);
	}

	setCommune(c) {
		// console.log("setCommune: " + JSON.stringify(c));
		this.selectedCommune = c;
		this.selectedCommuneName = c["name"];
		// console.log(this.selectedCommune);
	}

	setTrip(rtype) {
		if (rtype !== "md" && this.arrayFieldsModel.length > 1) {
			this.arrayFieldsModel.splice(1, 2);
		}
		this.rtType = rtype;
	}

	setClass(cabinClass) {
		// ECONOMY STANDARD       cabinClass=M
		// ECONOMY                cabinClass=Y
		// ECONOMY PREMIUM        cabinClass=W
		// BUSINESS               cabinClass=C
		// FIRST                  cabinClass=F

		this.cabinClass = this.classCorrespondances[cabinClass];
		this.cabinClassName = this.classTitles[cabinClass];
		this.displayMultipleCabinClass = false;
		if (this.classTitles[cabinClass] === "MULTIPLE") {
			this.displayMultipleCabinClass = true;
		}
	}

	prepareQueryParams(queryParams) {
		queryParams["arrayFieldsModel"] = this.arrayFieldsModel;
		for (let i = 0; i < this.arrayFieldsModel.length; i++) {
			if (this.arrayFieldsModel[i].departureItem["item"]) {
				this.arrayFieldsModel[i].departureItem =
					this.arrayFieldsModel[i].departureItem["item"];
			}

			if (this.arrayFieldsModel[i].returnItem["item"]) {
				this.arrayFieldsModel[i].returnItem = this.arrayFieldsModel[i].returnItem["item"];
			}

			queryParams["origin_" + (i + 1)] = this.arrayFieldsModel[i].departureItem["iata"];
			queryParams["destination_" + (i + 1)] = this.arrayFieldsModel[i].returnItem["iata"];

			queryParams["flightDepartureName_" + (i + 1)] =
				this.arrayFieldsModel[i].departureItem["display"];
			queryParams["departureDate_" + (i + 1)] = DateService.toStringDate(
				this.arrayFieldsModel[i].modelDeparture
			);
			queryParams["flightDepartureCityCountryName_" + (i + 1)] =
				this.arrayFieldsModel[i].departureItem["city_name"] +
				", " +
				this.arrayFieldsModel[i].departureItem["country_name"];

			if (queryParams["cabinClass"] === "X") {
				queryParams["cabinClass_" + (i + 1)] =
					this.arrayFieldsModel[i].cabinClass_out_bound;
			}
			if (this.rtType === "rt") {
				if (!this.flightService.openReturn && this.arrayFieldsModel[i].modelReturn) {
					queryParams["departureDate_2"] = DateService.toStringDate(
						this.arrayFieldsModel[i].modelReturn
					);
				}
				queryParams["flightArrivalName_2"] = this.arrayFieldsModel[i].returnItem["display"];
				queryParams["flightArrivalCityCountryName_1"] =
					this.arrayFieldsModel[i].returnItem["city_name"] +
					", " +
					this.arrayFieldsModel[i].returnItem["country_name"];
				queryParams["origin_2"] = this.arrayFieldsModel[i].returnItem["iata"];
				queryParams["destination_2"] = this.arrayFieldsModel[i].departureItem["iata"];
				if (queryParams["cabinClass"] === "X") {
					queryParams["cabinClass_" + (i + 2)] =
						this.arrayFieldsModel[i].cabinClass_in_bound;
				}
			} else {
				queryParams["flightArrivalName_" + (i + 1)] =
					this.arrayFieldsModel[i].returnItem["display"];
				queryParams["flightArrivalCityCountryName_" + (i + 1)] =
					this.arrayFieldsModel[i].returnItem["city_name"] +
					", " +
					this.arrayFieldsModel[i].returnItem["country_name"];
			}
		}
		return queryParams;
	}

	mcPrepareQueryParams(queryParams) {
		this.mcDepartures.forEach((dest, x) => {
			queryParams[`nrOfDepartureCities_${x+1}`] = dest.length
			dest.forEach((dep, y) => {
				if (dep.departureItem) {
					queryParams[`departureCity_${x+1}_${y+1}`] = dep.departureItem.iata
				}
			})
		})
		this.mcArrivals.forEach((dest, x) => {
			queryParams[`nrOfArrivalCities_${x+1}`] = dest.length
			dest.forEach((dep, y) => {
				if (dep.arrivalItem) {
					queryParams[`arrivalCity_${x+1}_${y+1}`] = dep.arrivalItem.iata
				}
			})
		})
		this.arrayFieldsModel.forEach((_, i) => {
			queryParams[`departureDate_${i+1}`] = DateService.toStringDate(this.mcDates[i])
		})
		queryParams['requestedFlightNonStop'] = 0
		queryParams['range'] = 0
		queryParams['flexible'] = false
		queryParams['bounds'] = this.arrayFieldsModel.length
		queryParams['requestedMultiCity'] = 1
		return queryParams
	}

	getQueryParams() {
		let queryParams = {};

		queryParams = {
			bounds: this.arrayFieldsModel.length,
			nrOfAdult: this.adultNumber,
			nrOfChild: this.childNumber,
			nrOfInfant: this.infantNumber,
			nrOfInfantWithSeat: this.infantSeatNumber,
			nrOfYouth: this.youthNumber,
			nrOfSenior: this.seniorNumber,
			tripType: this.rtType,
			cabinClass: this.cabinClass,
			requestedMultiCity: 0,
			requestedFlightNonStop: 0,
			range: 0,
			withBaggage: this.flightService.withBaggage,
			directFlight: this.flightService.directFlight,
			refundable: this.flightService.refundable,
			openReturn: this.flightService.openReturn,
			flexible: this.flightService.calendar,
		};

		if (this.rtType == "rt") {
			queryParams["bounds"] = 2;
		}

		queryParams = this.rtType == "mc" ? this.mcPrepareQueryParams(queryParams) : this.prepareQueryParams(queryParams);
		return queryParams;
	}

	enableSearch() {
		if (
			this.adultNumber +
				this.childNumber +
				this.infantNumber +
				this.youthNumber +
				this.seniorNumber >
			0
		) {
			if (
				this.adultNumber +
					this.childNumber +
					this.infantNumber +
					this.youthNumber +
					this.seniorNumber >
				9
			) {
				return false;
			} else {
				if (this.adultNumber + this.youthNumber + this.seniorNumber < this.infantNumber) {
					return false;
				} else {
					return true;
				}
			}
		} else {
			return false;
		}
	}

	searchError() {
		if (
			this.adultNumber +
				this.childNumber +
				this.infantNumber +
				this.youthNumber +
				this.seniorNumber >
			9
		) {
			this.shareVarsRef.showError = true;
			this.shareVarsRef.errorMessage = FLIGHT_PASSENGERS_NUMBER_ERROR;
		} else {
			if (this.adultNumber < this.infantNumber) {
				this.shareVarsRef.showError = true;
				this.shareVarsRef.errorMessage = FLIGHT_BABIES_NUMBER_ERROR;
			}
		}
	}

	searchFlightClick() {
		if (this.rtType == "mc") {
			let empty = false;
			this.mcDepartures.forEach(dep => {
				if (dep.length == 0) {
					empty = true;
				}
			})
			this.mcArrivals.forEach(arr => {
				if (arr.length == 0) {
					empty = true;
				}
			})
			if (empty) {
				this.shareVarsRef.showError = true;
				this.shareVarsRef.errorMessage = "Please add at least one departure or arrival city!";
				return;
			}
			this.setParamsToCookies()
		}

		if (this.checkFieldsData(this.rtType, this.arrayFieldsModel)) {
			if (environment.assets == "volz") {
				fbq("track", "Search");
				lintrk("track", { Search: true });
			}
			this.goToFlightList();
		} else {
			this.shareVarsRef.showError = true;
			this.shareVarsRef.errorMessage = "Invalid data provided!";
		}
	}

	checkFieldsData(rtType, arrayFieldsModel): boolean {
		let dataState: boolean = false;
		if (rtType == 'mc') {
			return this.validateMultiCity()
		}
		if (rtType != "rt" || this.flightService.openReturn) {
			for (let i = 0; i < arrayFieldsModel.length; i++) {
				if (
					arrayFieldsModel[i].departureItem !== null &&
					arrayFieldsModel[i].returnItem !== null &&
					arrayFieldsModel[i].modelDeparture !== null
				) {
					dataState = true;
				} else {
					dataState = false;
					break;
				}
			}
		} else {
			for (let i = 0; i < arrayFieldsModel.length; i++) {
				if (
					arrayFieldsModel[i].departureItem !== null &&
					arrayFieldsModel[i].returnItem !== null &&
					arrayFieldsModel[i].modelDeparture !== null &&
					arrayFieldsModel[i].modelReturn !== null
				) {
					dataState = true;
				} else {
					dataState = false;
					break;
				}
			}
		}

		return dataState;
	}
	goToFlightCalendar() {
		this.navigationService.gotToFlightCalendar();
	}

	goToFlightList() {
		ShareVars.isBusy = true;

		const params = this.getQueryParams();

		if (Object.keys(params).length > 0) {
			this.flightService.removeAllData();
			this.shareVarsRef.showFlightResultsError = false;
			this.shareVarsRef.showFlightResultsErrorMessage = null;

			// console.log("flight search params: ", params);

			this.flightService.setFlightSearchParams(FLIGHT_SEARCH_PARAMS, params);
			this.flightService.setSearchData(params);

			if (this.flightService.calendar && this.rtType != "md") {
				this.goToFlightCalendar();
				return;
			}
			if (this.searchSource == "flight_availability") {
				if (this.usingAvailabilityRouteParams) {
					this.router.navigate([], {
						queryParams: params,
						relativeTo: this.activatedRoute,
					});
				} else {
					this.router.onSameUrlNavigation = "reload";
					this.navigationService.goToFlightAvailabilityPage();
				}
				return;
			}
			this.navigationService.goToFlightAvailabilityPage();
		}
	}

	setParamsToCookies() {
		this.flightService.removeAllData();
		const params = this.getQueryParams();
		this.availabilityRouteParams = cloneDeep(params);
		this.flightService.setFlightSearchParams(FLIGHT_SEARCH_PARAMS, params);
	}

	initFlightSearch(urlParams?) {
		this.flightForm = this.formBuilder.group({
			leavingFrom: ["", Validators.required],
			leavingTo: ["", Validators.required],
			departureDate: ["", Validators.required],
			arrivalDate: ["", Validators.required],
		});

		let queryParams;

		if (this.flightService.getSearchData()) {
			queryParams = cloneDeep(this.flightService.getSearchData());
			this.rtType = queryParams['tripType']
			// console.log("init flight search using service params");
		} else {
			if (urlParams && Object.keys(urlParams).length > 0) {
				queryParams = cloneDeep(urlParams);

				if (Object.keys(queryParams).length == 0) {
					queryParams = null;
				} else {
					queryParams = this.baseService.fixFlightParamsFromURL(queryParams);
				}

				// console.log("init flight search using url", queryParams);
			} else {
				queryParams = this.flightService.getFlightSearchParams(FLIGHT_SEARCH_PARAMS);

				if (queryParams) {
					queryParams = JSON.parse(queryParams);
					// console.log("init flight search using cookies");
				}
			}
		}

		if (queryParams && queryParams["arrayFieldsModel"]) {
			this.availabilityRouteParams = cloneDeep(queryParams);
			this.arrayFieldsModel.length = 0;

			this.adultNumber = queryParams["nrOfAdult"];
			this.childNumber = queryParams["nrOfChild"];
			this.infantNumber = queryParams["nrOfInfant"];

			this.infantSeatNumber = queryParams["nrOfInfantWithSeat"];
			this.youthNumber = queryParams["nrOfYouth"];
			this.seniorNumber = queryParams["nrOfSenior"];

			this.rtType = queryParams["tripType"];
			this.cabinClass = queryParams["cabinClass"];
			this.cabinClassName =
				this.classTitles[this.classCorrespondances.indexOf(this.cabinClass)];

			if (this.cabinClassName === "MULTIPLE") {
				this.displayMultipleCabinClass = true;
			}

			if (queryParams["tripType"] == "rt") {
				queryParams["bounds"] = 1;
			}
			for (let i = 0; i < queryParams["bounds"]; i++) {
				let fieldsModel = new FlightSearchFiledsModel();

				fieldsModel.flightDepartureName = queryParams["flightDepartureName_" + (i + 1)];
				fieldsModel.modelDeparture = DateService.toObjectDate(
					fieldsModel.modelDeparture,
					queryParams["departureDate_" + (i + 1)]
				);

				if (
					this.baseService.compareDates(
						DateService.getTodayDate(),
						fieldsModel.modelDeparture
					) == 1
				) {
					fieldsModel.modelDeparture = null;
				}

				//add min date to others, cuz i==0 is already set
				if (i > 0) {
					ShareVars.minDateFlightSearch[i] = this.arrayFieldsModel[i - 1].modelDeparture;
				}

				fieldsModel.departureItem = queryParams["arrayFieldsModel"][i]["departureItem"];
				this.searchParamsDisplayName[
					i
				].departureName = `${fieldsModel.departureItem.name} - ${fieldsModel.departureItem.country_name}`;
				this.searchParamsDisplayName[
					i
				].departureDisplay = `${fieldsModel.departureItem.city_name} (${fieldsModel.departureItem.iata})`;

				fieldsModel.returnItem = queryParams["arrayFieldsModel"][i]["returnItem"];
				this.searchParamsDisplayName[
					i
				].arrivalName = `${fieldsModel.returnItem.name} - ${fieldsModel.returnItem.country_name}`;
				this.searchParamsDisplayName[
					i
				].arrivalDisplay = `${fieldsModel.returnItem.city_name} (${fieldsModel.returnItem.iata})`;

				if (queryParams["cabinClass_" + (i + 1)]) {
					fieldsModel.cabinClass_out_bound = queryParams["cabinClass_" + (i + 1)];
					fieldsModel.cabinClassName_out_bound =
						this.classTitles[
							this.classCorrespondances.indexOf(fieldsModel.cabinClass_out_bound)
						];
				}

				if (this.rtType == "rt") {
					fieldsModel.flightArrivalName = queryParams["flightArrivalName_2"];
					if (!this.flightService.openReturn && queryParams["departureDate_2"]) {
						fieldsModel.modelReturn = DateService.toObjectDate(
							fieldsModel.modelReturn,
							queryParams["departureDate_2"]
						);

						if (
							this.baseService.compareDates(
								DateService.getTodayDate(),
								fieldsModel.modelReturn
							) == 1
						) {
							fieldsModel.modelReturn = null;
						}
					}

					if (queryParams["cabinClass_" + (i + 2)]) {
						fieldsModel.cabinClass_in_bound = queryParams["cabinClass_" + (i + 2)];
						fieldsModel.cabinClassName_in_bound =
							this.classTitles[
								this.classCorrespondances.indexOf(fieldsModel.cabinClass_in_bound)
							];
					}
				} else {
					fieldsModel.flightArrivalName = queryParams["flightArrivalName_" + (i + 1)];
				}

				this.arrayFieldsModel.push(fieldsModel);
			}
			this.rtType = queryParams["tripType"]
		} else if (queryParams['tripType'] == 'mc') {
			this.rtType = 'mc'
			for (let i = 0; i < queryParams["bounds"]; i++) {
				if (this.arrayFieldsModel.length == this.mcDepartures.length) break;
				let fieldsModel = new FlightSearchFiledsModel();
				this.arrayFieldsModel.push(fieldsModel);
			}
		} else {
			this.rtType = "rt";
		}
	}

	getFlightObjByCityName(data, airportName, dataObj) {
		for (let obj of data) {
			if (obj["iata"] === airportName) {
				dataObj = obj;
				return dataObj;
			}
		}
	}

	initSearchFields() {
		this.arrayFieldsModel.length = 0;
		let fieldsModel = new FlightSearchFiledsModel();
		ShareVars.minDateFlightSearch[0] = DateService.getTodayDate();
		this.arrayFieldsModel.push(fieldsModel);
	}

	addMultiDestination() {
		if (this.rtType == "mc") {
			this.mcDepartures.push([{
				departureName: "",
				departureDisplay: "",
				departureItem: null
			}])
			this.mcArrivals.push([{
				arrivalName: "",
				arrivalDisplay: "",
				arrivalItem: null
			}])
			let fieldsModel = new FlightSearchFiledsModel();
			ShareVars.minDateFlightSearch[this.arrayFieldsModel.length] =
				this.arrayFieldsModel[this.arrayFieldsModel.length - 1].modelDeparture;
			this.arrayFieldsModel.push(fieldsModel);
			return
		}
		if (
			this.arrayFieldsModel[this.arrayFieldsModel.length - 1].modelDeparture &&
			this.arrayFieldsModel[this.arrayFieldsModel.length - 1].departureItem &&
			this.arrayFieldsModel[this.arrayFieldsModel.length - 1].returnItem &&
			this.arrayFieldsModel[this.arrayFieldsModel.length - 1].flightDepartureName &&
			this.arrayFieldsModel[this.arrayFieldsModel.length - 1].flightArrivalName
		) {
			let fieldsModel = new FlightSearchFiledsModel();
			ShareVars.minDateFlightSearch[this.arrayFieldsModel.length] =
				this.arrayFieldsModel[this.arrayFieldsModel.length - 1].modelDeparture;
			this.arrayFieldsModel.push(fieldsModel);
		}
	}

	removeMultiDestination(index) {
		if (this.arrayFieldsModel.length > 1) {
			this.arrayFieldsModel.splice(index, 1);
			ShareVars.minDateFlightSearch.splice(index, 1);

			//case we removed the 1st one, the 2nd one's minDate changes
			ShareVars.minDateFlightSearch[0] = DateService.getTodayDate();
			this.mcDepartures.splice(index, 1)
			this.mcArrivals.splice(index, 1)
			this.mcDates.splice(index, 1)
			this.setLocalStorateMc()
			if (this.arrayFieldsModel.length > 1) {
				ShareVars.minDateFlightSearch[1] = this.arrayFieldsModel[0].modelDeparture;
			}
		}
	}

	decrement($event) {
		switch ($event) {
			case "ADT":
				this.adultNumber = this.checkValueDecrement(this.adultNumber);
				return;

			case "CHD":
				this.childNumber = this.checkValueDecrement(this.childNumber);
				return;

			case "INF":
				this.infantNumber = this.checkValueDecrement(this.infantNumber);
				return;

			case "INF_SEAT":
				this.infantSeatNumber = this.checkValueDecrement(this.infantSeatNumber);
				return;

			case "YTH":
				this.youthNumber = this.checkValueDecrement(this.youthNumber);
				return;

			case "SNR":
				this.seniorNumber = this.checkValueDecrement(this.seniorNumber);
				return;
		}
	}

	checkValueDecrement(value: number): number {
		if (value > 0) {
			value--;
		}

		return value;
	}

	increment($event) {
		switch ($event) {
			case "ADT":
				this.adultNumber = this.checkValueIncrement(this.adultNumber);
				return;

			case "CHD":
				this.childNumber = this.checkValueIncrement(this.childNumber);
				return;

			case "INF":
				this.infantNumber = this.checkValueIncrement(this.infantNumber);
				return;

			case "INF_SEAT":
				this.infantSeatNumber = this.checkValueIncrement(this.infantSeatNumber);
				return;

			case "YTH":
				this.youthNumber = this.checkValueIncrement(this.youthNumber);
				return;

			case "SNR":
				this.seniorNumber = this.checkValueIncrement(this.seniorNumber);
				return;
		}
	}

	checkValueIncrement(value: number): number {
		if (value < 9) {
			value++;
		}

		return value;
	}

	mcAddDeparture(flightIndex) {
		if (this.mcDepartures[flightIndex].length+this.mcArrivals[flightIndex].length < 6) {
			this.mcDepartures[flightIndex].push({
				departureName: "",
				departureDisplay: "",
				departureItem: null
			});
		}
	}

	mcAddArrival(flightIndex) {
		if (this.mcArrivals[flightIndex].length+this.mcArrivals[flightIndex].length < 6) {
			this.mcArrivals[flightIndex].push({
				arrivalName: "",
				arrivalDisplay: "",
				arrivalItem: null
			});
		}
	}

	selectMcDeparture(p, fi, i) {
		if (!p) {
			this.mcDepartures[fi][i]['departureName'] = ""
			this.mcDepartures[fi][i]['departureDisplay'] = ""
			this.mcDepartures[fi][i]['departureItem'] = null;
			return
		}
		this.mcDepartures[fi][i]['departureName'] = p["item"]["display"];
		this.mcDepartures[fi][i]['departureDisplay'] = `${p["item"]["city_name"]} (${p["item"]["iata"]})`;
		this.mcDepartures[fi][i]['departureItem'] = p["item"];
		localStorage.setItem("flightMcDepartures", JSON.stringify(this.mcDepartures))
	}

	selectMcArrival(p, fi, i) {
		if (!p) {
			this.mcArrivals[fi][i]['arrivalName'] = ""
			this.mcArrivals[fi][i]['arrivalDisplay'] = ""
			this.mcArrivals[fi][i]['arrivalItem'] = null;
			return
		}
		this.mcArrivals[fi][i]['arrivalName'] = p["item"]["display"];
		this.mcArrivals[fi][i]['arrivalDisplay'] = `${p["item"]["city_name"]} (${p["item"]["iata"]})`;
		this.mcArrivals[fi][i]['arrivalItem'] = p["item"];
		localStorage.setItem("flightMcArrivals", JSON.stringify(this.mcArrivals))
	}

	handleMcChangeDate(e, fi) {
		this.mcDates[fi] = e;
		const dates_parsed = this.mcDates.map(d => `${d.year}-${d.month}-${d.day}`)
		localStorage.setItem("flightMcDates", JSON.stringify(dates_parsed))
	}

	validateMultiCity() {
		if (this.mcDepartures.length !== this.mcArrivals.length) return false
		for (let dest=0; dest<this.mcDepartures.length; dest++) {
			for (let dep=0; dep<this.mcDepartures[dest].length; dep++) {
				if (!this.mcDepartures[dest][dep].departureItem) return false
			}
		}
		for (let dest=0; dest<this.mcArrivals.length; dest++) {
			for (let arr=0; arr<this.mcArrivals[dest].length; arr++) {
				if (!this.mcArrivals[dest][arr].arrivalItem) return false
			}
		}
		return true
	}

	setLocalStorateMc() {
		localStorage.setItem('flightMcDepartures', JSON.stringify(this.mcDepartures))
		localStorage.setItem('flightMcArrivals', JSON.stringify(this.mcArrivals))
	}
}
