import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BaseService } from "../../../shared/services/base.service";
import { NavigationService } from "../../../shared/services/navigation.service";
import { PackagesService } from "../../../shared/services/packages.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { IMAGES_URL } from "../../../shared/services/urls_b2b";
import { forEach } from "lodash";

@Component({
	selector: "app-package-item",
	templateUrl: "./package-item.component.html",
	styleUrls: ["./package-item.component.scss"],
})
export class PackageItemComponent implements OnInit {
	@Input() packageObj;
	@Input() itemIndex;
	@Input() showMessageInstead = false;
	@Output() getPackage = new EventEmitter<any>();

	departureFlightTest = true;
	departureTransferTest = true;

	showIconsFlight = true;
	showIconsTransfer = true;

	hotelSteps = [];
	flightSteps = [];
	transferSteps = [];

	imagesURL = IMAGES_URL;

	constructor(
		private baseService: BaseService,
		private navigationService: NavigationService,
		private packagesService: PackagesService
	) {}

	ngOnInit() {
		this.initSteps();
	}

	initSteps() {
		if (!this.packageObj.steps) return
		for (let step of this.packageObj.steps) {
			switch (step.type) {
				case "HOTEL":
					this.hotelSteps.push(step);
					break;

				case "FLIGHT":
					if (this.flightSteps.length == 0) {
						this.flightSteps.push(step);
					}
					break;

				case "TRANSFER":
					if (this.transferSteps.length == 0) {
						this.transferSteps.push(step);
					}
					break;
			}
		}
	}

	minimalRoomPrice(rooms, adults, children) {
		let minimalPrice = 10000000000000;
		forEach(rooms, (room, index) => {
			forEach(room.rates, (rate, index) => {
				if (rate.adults === adults && rate.children_number === children && rate.price > 0) {
					if (rate.price < minimalPrice) {
						minimalPrice = rate.price;
					}
				}
			});
		});
		return minimalPrice;
	}

	getPrice() {
		let search = this.packagesService.packageBookingSearchParams;
		let adults = 0;
		let children = 0;
		for (let i = 1; i <= search.room; i++) {
			adults = adults + search["adults_" + i];
			children = children + search["children_" + i];
		}

		if (this.packageObj.simple_mode) {
			if(this.packageObj.package.is_combinatory){
				let minPrice = Infinity;
				this.packageObj.package.combinations.forEach((combination) => {
					if(minPrice > combination.price){
						minPrice = combination.price;
					}
				});
				return minPrice;
			} else {
				let minPrice = Infinity;
				this.packageObj.package.prices.forEach((price) => {
					if(minPrice > price.adt_price){
						minPrice = price.adt_price;
					}
				});
				return minPrice;
			}
			return this.packageObj.price.total;
		}

		let sum =
			this.packageObj.price.agency_markup +
			this.packageObj.price.platform_markup +
			this.packageObj.price.provider_markup; //this.packageObj.price.total;
		for (let step of this.packageObj.steps) {
			if (step.type === "HOTEL") {
				let price = this.minimalRoomPrice(step.hotel.rooms, adults, children);
				sum = sum + price * this.packageObj.package.nb_nights;
			}
			if (step.type === "FLIGHT") {
				sum =
					sum +
					adults * step.flight.price.adt_price +
					children * step.flight.price.chd_price;
			}
			if (step.type === "TRANSFER") {
				sum =
					sum +
					adults * step.transfer.price.adt_price +
					children * step.transfer.price.chd_price;
			}
			if (step.type === "EXCURSION") {
				sum =
					sum +
					adults * step.excursion.price.adt_price +
					children * step.excursion.price.chd_price;
			}
		}

		return sum; //*this.packageObj.package.nb_nights;
	}

	showPackageDetails(): void {
		if (this.packageObj.package.available_places <= 0) return;
		this.getPackage.emit(this.packageObj);
		if (!this.showMessageInstead) {
			this.packagesService.setPackageBookingPackageObject(this.packageObj);
			this.navigationService.goToPackageDetailsPage();
		}
	}

	formatPrice(price: number) {
		return this.baseService.formatPrice(price);
	}

	getImage() {
		const i = this.packageObj.package.picture
		if (i.length) return this.imagesURL + i
		return "/assets/common/images/placeholder.jpg"
	}

	getImageURL() {
		const i = this.packageObj.package.picture
		if (i.length) return `url(${this.imagesURL + i})`
		return "url(/assets/common/images/placeholder.jpg)"
	}

	getStars(stars: string) {
		let s = 5
		switch (stars) {
			case "four":
				s = 4
				break
			case "three":
				s = 3
				break
			case "two":
				s = 2
				break
			case "one":
				s = 1
			default:
				s = 5
				break
		}
		return s
	}

	parseText(text?: string, length?: number, lowerCase?: boolean) {
		const itemsToRemove = ['<br>', '<br/>', '<br />', '<b>', '</b>', '<i>', '</i>', '<p>', '</p>', '<strong>', '</strong>', '0', '.']
		let parsedText = text
		if (!text) return ""
		itemsToRemove.forEach((item) => {
			parsedText = this.deleteSubString(parsedText, item)
		})
		if (length && parsedText.length > length) {
			parsedText = `${parsedText.slice(0, length)}...`
		}
		if (lowerCase) return parsedText.toLowerCase()
		return parsedText
	}

	deleteSubString(stack: string, sub: string) {
		let result = stack
		while (result.indexOf(sub) !== -1) {
			result = result.replace(sub, '')
		}
		return result
	}

	formatDate(dateString: string) {
		let date = dateString.split(' ')[0]
		return date
	}

	formatSeeMore(str: string, length: number) {
		return str.replace(' ', ` ${length} `)
	}
}
