import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { ShareVars } from "../../services/share.vars";
import { NavigationService } from "../../services/navigation.service";
import { IMAGES_URL } from "../../../shared/services/urls_b2b";
import { environment } from "environments/environment";
import { PackagesService } from "@app/shared/services/packages.service";

declare var QRCode: any;

@Component({
	selector: "app-voucher",
	templateUrl: "./voucher.component.html",
	styleUrls: ["./voucher.component.scss"],
})
export class VoucherComponent implements OnInit {
	@Input() confirmationData: any;

	@Output() printBookinEvent = new EventEmitter<any>();
	@Output() sendBookingEmailEvent = new EventEmitter<any>();
	isB2BUser: boolean = false;
	packageObj = null;
	imagesURL = IMAGES_URL;

	imageLogo = "LOGO_LARGE.png";
	assetClientName = null;
	qrcodedata: string = "";
	token = null;
	agencyLogo = null;
	static = "http://static.algebratec.com/assets/logos/airline/";

	bookedHotels = []
	policies = []

	constructor(
		private baseService: BaseService,
		private navigationService: NavigationService,
		private packageService : PackagesService
	) {}

	ngOnInit() {
		this.assetClientName = environment.assets;
		if (this.assetClientName == "triponline") {
			this.imageLogo = "logo-tpo-large-vertical.png";
		}

		this.token = this.baseService.getToken();
		this.qrcodedata = this.baseService.getPrintBookingUrl(
			this.confirmationData.booking.id,
			this.token,
			"B2B",
			"PACKAGE"
		);
		setTimeout(() => {
			let doc = document.getElementById("qrcode");
			new QRCode(doc, this.qrcodedata);
		}, 1000);

		this.isB2BUser = this.baseService.b2bUserType == "B2B";
		this.agencyLogo = environment.staticApi+this.confirmationData.booking.agency.logo;
		if(this.confirmationData.booking.sub_package.package.is_combinatory){
			this.prepareHotels();
			this.getCancellationPolicyCombinatory()
		}

		
	}
	getPaxes(){
		const packageObj = this.confirmationData.booking.sub_package.package
		let n_adt = 0
		let n_chd1 = 0
		let n_chd2 = 0

		this.confirmationData.booking.booked_rooms[0].pax_in_rooms.forEach( (room) => {
			if(room.pax.type === "ADT") n_adt++ 
			if(room.pax.type === "CHD") {
				if(room.pax.age >= packageObj.chd1_age_min && room.pax.age < packageObj.chd1_age_max) n_chd1++
				if(room.pax.age >= packageObj.chd2_age_min && room.pax.age < packageObj.chd2_age_max) n_chd2++
			}
		})

		console.log([n_adt , n_chd1 , n_chd2])
		return [n_adt , n_chd1 , n_chd2]
	}


	getCancellationPolicyCombinatory() {
		const [n_adt , n_chd1 , n_chd2] = this.getPaxes()
		const payload = {
			package_id : this.confirmationData.booking.sub_package.package.id ,
			selected_combinations: this.confirmationData.booking.booking_combinations.map(c=>c.combination_id) ,
			selected_supplements: this.confirmationData.booking.booking_supplements.map(c=>c.supplement_id),
			n_adt ,
			n_chd1 ,
			n_chd2 , 
		}
		this.packageService.getPackageCancellationPolicyCombinatory( payload).subscribe( 
			(res) => {
				if (res.success) this.policies = res.policies
			},
			(err) => {
				console.log(err);
			}
		);
	}
	prepareHotels(){
		this.bookedHotels = []
		const hotels = this.getHotelsFromSteps()
		this.confirmationData.booking.booked_rooms.forEach( (room , index) => {
			const rooms = []
			const groupedPaxes = this.groupedPaxes( room.pax_in_rooms )
			Object.keys( groupedPaxes ).forEach( ( roomCode , i) => {
				const room = {
					name: this.confirmationData.booking.booking_combinations[i].name,
					guests:  groupedPaxes[roomCode] ,
					pension: this.confirmationData.booking.booking_combinations[i].pension
				}
				if(this.confirmationData.booking.sub_package.package.is_combinatory){
					room.guests.forEach((guest)=>{
						if(guest.age < 2){
							guest.type = "INF"
						}
					})
				}
				console.log(room)
				rooms.push(room)
			})
			const hotel = {
				name: hotels[index].name,
				rooms : rooms,
			}

			this.bookedHotels.push(hotel)
		})
		 
	}	

	getHotelsFromSteps(){
	const hotels = [ ]
	this.confirmationData.booking.sub_package.steps.forEach( (step) => {
		if( step.type === "HOTEL" ) {
			hotels.push(step.hotel)
		}
	})
	return hotels
	}

	groupedPaxes( paxes ){
	const groupedPassengers = paxes.reduce((acc, passenger) => {
		const roomCode = passenger.room_code;
		if (!acc[roomCode]) {
			acc[roomCode] = []; 
		}
		acc[roomCode].push(passenger.pax); 
		return acc;
	}, {});

	return groupedPassengers
	}


	getNumberOfGuestsInRooms( rooms ){
	let total = 0
	return rooms.reduce( (acc , room) => {
		total += room.guests.length } , 0)  

	}
	

	printVoucher() {
		this.printBookinEvent.emit();
	}

	openEmailModal() {
		this.sendBookingEmailEvent.emit();
	}

	goToHomePage() {
		this.navigationService.goToHomePage();
	}

	goToPackageManageBookingsPage() {
		ShareVars.isBusy = true;
		this.navigationService.goToB2BPackageVoucher(this.confirmationData.booking.id);
	}

	mapPackageObj() {
		this.packageObj = {};
		this.packageObj["package"] = {};
		this.packageObj["package"]["name"] =
			this.confirmationData.booking["sub_package"]["package"]["name"];
		this.packageObj["package"]["picture"] =
			this.confirmationData.booking["sub_package"]["package"]["picture"];
		this.packageObj["package"]["departure"] = {};
		this.packageObj["package"]["departure"]["start_date"] =
			this.confirmationData.booking["start_date"];
		this.packageObj["package"]["departure"]["end_date"] =
			this.confirmationData.booking["end_date"];
	}

	formatPrice(price: number) {
		return this.baseService.formatPrice(price);
	}

	isArabic() {
		return this.baseService.isArabic();
	}
}
