export const AVAILABILITY_NOT_FOUND = "NO_RESULTS_FOUND";
export const ERROR_MESSAGE_GETTING_DATA = "ERROR_MESSAGE_GETTING_DATA";
export const ERROR_MESSAGE_INSUFFICIENT_FUNDS = "ERROR_MESSAGE_INSUFFICIENT_FUNDS";
export const ERROR_INTERNET_CONNECTION = "ERROR_INTERNET_CONNECTION";
export const ERROR_ACTION_NOT_AUTHORIZED = "ERROR_ACTION_NOT_AUTHORIZED";
export const ERROR_ACTION_NOT_AUTHORIZED_TITLE = "ERROR_ACTION_NOT_AUTHORIZED_TITLE";
export const ERROR_ACTION_FUNDS_TITLE = "ERROR_ACTION_FUNDS_TITLE";
export const CANCEL_BOOKING_IMPOSSIBLE = "CANCEL_BOOKING_IMPOSSIBLE";

export const CONFIRMATION_BOOKING_WAITING_MESSAGE = "CONFIRMATION_BOOKING_WAITING_MESSAGE";
export const CHECKING_BOOKING_WAITING_MESSAGE = "CHECKING_BOOKING_WAITING_MESSAGE";
export const CHECKING_BOOKING_WAITING_MESSAGE_VOLZ = "CHECKING_BOOKING_WAITING_MESSAGE_VOLZ";
export const ERROR_CONFIRMATION_BOOKING_MESSAGE = "ERROR_CONFIRMATION_BOOKING_MESSAGE";
export const CANCELED_CONFIRMATION_BOOKING_MESSAGE = "CANCELED_CONFIRMATION_BOOKING_MESSAGE";
export const SOME_ERROR_OCCURRED = "SOME_ERROR_OCCURRED";
export const PAYMENT_NOT_AVAILABLE_B2C = "PAYMENT_NOT_AVAILABLE_B2C";
export const FLIGHT_NOT_BOOKABLE_MESSAGE = "FLIGHT_NOT_BOOKABLE_MESSAGE";
export const HOTEL_NOT_BOOKABLE_MESSAGE = "HOTEL_NOT_BOOKABLE_MESSAGE";
export const ROOM_RATE_NOT_BOOKABLE_MESSAGE = "ROOM_RATE_NOT_BOOKABLE_MESSAGE";
export const SOLD_NOT_ENOUGH_CONFIRMATION_BOOKING_MESSAGE =
	"SOLD_NOT_ENOUGH_CONFIRMATION_BOOKING_MESSAGE";
export const PRICE_DISCREPANCY_BOOKING_MESSAGE = "PRICE_DISCREPANCY_BOOKING_MESSAGE";

export const FLIGHT_PASSENGERS_NUMBER_ERROR = "FLIGHT_PASSENGERS_NUMBER_ERROR";
export const FLIGHT_BABIES_NUMBER_ERROR = "FLIGHT_BABIES_NUMBER_ERROR";
export const CHOOSE_RECOMMENDATION_ERROR_MESSAGE = "CHOOSE_RECOMMENDATION_ERROR_MESSAGE";
export const CHOOSE_RECOMMENDATION_ERROR_MESSAGE_OW = "CHOOSE_RECOMMENDATION_ERROR_MESSAGE_OW";

export const SIGNUP_ERROR_MESSAGE = "SIGNUP_ERROR_MESSAGE";

export const HOTEL_ITEM_NOT_AVAILABLE = "HOTEL_ITEM_NOT_AVAILABLE";

export const LOADER_MESSAGE_FLIGHT = "LOADER_MESSAGE_FLIGHT";
export const LOADER_MESSAGE_PACKAGE = "LOADER_MESSAGE_PACKAGE";
export const LOADER_MESSAGE_HOTEL = "LOADER_MESSAGE_HOTEL";
export const LOADER_MESSAGE_HOTEL_ITEM = "LOADER_MESSAGE_HOTEL_ITEM";

export const ERROR_MESSAGE_MINOR_SINGLE_NOT_ALLOWED = "ERROR_MESSAGE_MINOR_SINGLE_NOT_ALLOWED";

export const PACKAGE_EMPTY_DESTINATION = "PACKAGE_EMPTY_DESTINATION";
export const BOOKING_CONFIRMED_WITH_ERRORS = "BOOKING_CONFIRMED_WITH_ERRORS";

export const ERROR_CHECK_ADMIN = "ERROR_CHECK_ADMIN";
export const PNR_IMPORT_ERROR = "PNR_IMPORT_ERROR";
export const PNR_IMPORT_EXIST_ERROR = "PNR_IMPORT_EXIST_ERROR";
export const PNR_IMPORT_ZONEB_ERROR = "PNR_IMPORT_ZONEB_ERROR";
export const DELEVERY_BOOKING_MESSAGE = "DELEVERY_BOOKING_MESSAGE";

export const CONFIRMATION_BOOKING_CANCELED = "CONFIRMATION_BOOKING_CANCELED";
export const CONFIRMATION_BOOKING_PENDING = "CONFIRMATION_BOOKING_PENDING";
export const HOTEL_BOOKING_REDIRECT_MESSAGE = "HOTEL_BOOKING_REDIRECT_MESSAGE";
